import React from 'react';
import { graphql} from 'gatsby';
import PricingPlans from '../../templates/multilanguage/PricingPlans';

export default ({ data }) => {
  return <PricingPlans data={data} />
};


export const query = graphql`
 query PricingRo {
  mdx(frontmatter: {type: {eq: "pricing"} language: {eq: "it"}}) {
      id
      fields {
        slug
        canonical
        lang
        relatedPages {
          en {
            url
            country
          }
          it {
            url
            country
          }
          ro {
            url
            country
          }
          fr {
            url
            country
          }
          es {
            url
            country
          }
          de {
            url
            country
          }
          pl {
            url
            country
          }
          cz {
            url
            country
          }
        }
      }
      exports {
        plans {
          name
          prices
          subtexts
          description
          features {
            name
            icon
            languages
          }
          fullListText
          buttonText
          buttonHref
        }
        features {
          title
          list {
            name
            icon
            description
          }
        }
      }
      frontmatter {
        type
        title
        subtitle
        descriptionText
        switcherTexts
        euText
        mobileText
        discountPolicyTitle
        discountPolicySubtitle
        discountPolicyList
        salesRequests
        metaTitle
        metaDescription
        metaImage {
          publicURL
        }
        metaCover {
          publicURL
        }
        metaKeywords
        twitterCard
        ldJson
      }
  }
}
`
