import React, { useState, useEffect } from 'react';
import { hostname } from '../../utils/constants';
import SiteLayout from '../../components/layout/SiteLayout';
import { Link } from 'gatsby';
import Switch from 'react-switch';
import AppleStore from '../../svg/AppleStore';
import GooglePlay from '../../svg/GooglePlay';
import loadable from '@loadable/component';
import { Cookies } from 'react-cookie-consent';
const RoContact = loadable(() => import('../../templates/homepage/ro-contact'));

const PricingPlans = ({ data }) => {
  const [planSwitched, setPlanSwitched] = useState(true);

  let ldJsonHome = "Pagina Principale";
  let ldJsonLevel1Route = "/tarrifa/";
  let ldJsonLevel1Name = "Piani tariffari";

  const isBrowser = typeof window !== "undefined";

  const [width, setWidth] = useState(isBrowser? window.innerWidth : 0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const onStartFreeTrial = () => {
    if (isBrowser) {
      let elapseitId = Cookies.get("elapseit_id");
      if (window && window.gtag) {
        window.gtag('event', 'sign_up', {
          'method': 'PricingPlans'
        });
        if (!isMobile) {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-815257767/7PAgCLebrs8DEKex34QD',
            'event_callback': () => {
            }
          });
        }
      }

      if (elapseitId) {
        window.open("https://app.elapseit.com/register?elapseitId=" + elapseitId + "&language=" + data?.mdx?.fields?.lang, "_blank");
      } else {
        window.open("https://app.elapseit.com/register?language=" + data?.mdx?.fields?.lang, "_blank");
      }
    }
  };

  let navigationLdJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": hostname + "/",
          "name": ldJsonHome
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": hostname + ldJsonLevel1Route,
          "name": ldJsonLevel1Name
        }
      }
    ]
  };

  return (
    <SiteLayout relatedPages={data?.mdx?.fields?.relatedPages} lang={data?.mdx?.fields?.lang} canonical={data?.mdx?.fields?.canonical} title={"Pricing Plans"} seo={{metaTitle: data?.mdx?.frontmatter?.metaTitle, metaDescription: data?.mdx?.frontmatter?.metaDescription, metaImage: data?.mdx?.frontmatter?.metaImage?.publicURL, metaCover: data?.mdx?.frontmatter?.metaCover?.publicURL, twitterCard: data?.mdx?.frontmatter?.twitterCard, ldJson: data?.mdx?.frontmatter?.ldJson, navigationLdJson: navigationLdJson, keywords: data?.mdx?.frontmatter?.metaKeywords, slug: data?.mdx?.fields?.slug.replace("/posts/index", "")}}>
      <div className={"w-full flex flex-col features-bg-image px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96  "}>
        <div className={"w-full"}>
          <p className={"text-right w-full text-sm text-gray-500 mt-5"}><Link to={"/"}>Pagina Principale</Link> > Piani tariffari</p>
        </div>
        <h1 className={"text-xl md:text-2xl lg:text-4xl text-center text-primary-default font-light py-20"}>{data?.mdx?.frontmatter?.title}</h1>
      </div>
      <div className={"w-full flex flex-col px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96   py-10"}>
        <h2 className={"text-lg md:text-xl lg:text-2xl text-primary-default font-light text-left"}>{data?.mdx?.frontmatter?.subtitle}</h2>
        <p className={"py-10 lg:text-lg text-gray-500"}>{data?.mdx?.frontmatter?.descriptionText}</p>
      </div>
      <div className={"w-full flex flex-col px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96   py-10"}>
        <div className={"flex flex-row items-center self-center"}>
          <p className={"text-xs md:text-sm text-gray-600 w-24 md:w-32 mr-5" + (!planSwitched ? " font-bold" : "")}>{data?.mdx?.frontmatter?.switcherTexts[0]}</p>
          <Switch offColor={"#d3d3d3"} onColor={"#00ab6b"} uncheckedIcon={null} checkedIcon={null} onChange={() => setPlanSwitched(!planSwitched)} checked={planSwitched} />
          <p className={"text-xs md:text-sm text-gray-600 w-24  md:w-32 ml-5" + (planSwitched ? " font-bold" : "")}>{data?.mdx?.frontmatter?.switcherTexts[1]}</p>
        </div>
        <div className={"w-full flex flex-col md:flex-row items-center self-center pt-10"}>
          <div className={"w-full sm:w-2/3 md:w-1/3 px-5 xxl:px-20 cursor-default transition-all duration-300 zoom-on-hover pb-10 md:pb-0"}>
            <div className={"w-full flex flex-col bg-primary-blockquote pt-5 rounded-lg shadow-xl"}>
              <p className={"text-white text-lg md:text-xl text-left px-5 pb-5"}>
                {data?.mdx?.exports?.plans[0].name}
              </p>
              <div className={"flex flex-col w-full bg-primary-default py-5 border-b-8 border-primary-blockquote"}>
                <p className={"text-white text-xl md:text-2xl text-center"}>
                  {planSwitched ? data?.mdx?.exports?.plans[0].prices[1] : data?.mdx?.exports?.plans[0].prices[0]}
                </p>
                <p className={"text-white text-center text-lg"}>
                  {planSwitched ? data?.mdx?.exports?.plans[0].subtexts[1] : data?.mdx?.exports?.plans[0].subtexts[0]}
                </p>
              </div>
              <div className={"flex flex-col w-full bg-white text-gray-500 font-semibold text-sm p-5"}>
                <p className={"text-center"}>{data?.mdx?.exports?.plans[0].description}</p>
                <div className={"pt-5 pb-20 h-96"}>
                  {data?.mdx?.exports?.plans[0].features.map((feature, index) => {

                    return !feature.languages || !feature.languages.length ? (
                      <div className={"flex flex-row"} key={index}>
                        <i className={feature.icon + " text-primary-default"} />
                        <p className={"pb-2 pl-2 font-light"}>{feature.name}</p>
                      </div>
                    ) : null;})}
                  {data?.mdx?.exports?.plans[0].features.map((feature, index) => {

                    return feature.languages && feature.languages.length ? (
                      <div className={"flex flex-col"}>
                        <div className={"flex flex-row"} key={index}>
                          <i className={feature.icon + " text-primary-default"} />
                          <p className={"pb-2 pl-2 font-light"}>{feature.name}</p>
                        </div>
                        <div className={"flex flex-row"}>
                          <i className={feature.icon + " text-white"} />
                          <span className={"font-light pl-2"}>({feature.languages.map((language, index) => (index < feature.languages.length - 1 ? (language + ", ") : language))})</span>
                        </div>
                      </div>
                    ) : null;})}
                </div>
              </div>
              <a onClick={onStartFreeTrial} className={"w-full bg-primary-default border-t-8 border-b-8 border-primary-blockquote p-5 text-center cursor-pointer"}>
                <p className={"w-full text-center text-white hover:text-white text-xl"}>{data?.mdx?.exports?.plans[0].buttonText}</p>
              </a>
            </div>
          </div>
          <div className={"w-full sm:w-2/3 md:w-1/3 px-5 xxl:px-20 cursor-default transition-all duration-300 zoom-on-hover pb-10 md:pb-0"}>
            <div className={"w-full flex flex-col bg-primary-blockquote pt-5 rounded-lg shadow-xl"}>
              <p className={"text-white text-lg md:text-xl text-left px-5 pb-5"}>
                {data?.mdx?.exports?.plans[1].name}
              </p>
              <div className={"flex flex-col w-full bg-primary-default py-5 border-b-8 border-primary-blockquote"}>
                <p className={"text-white text-xl md:text-2xl text-center"}>
                  {planSwitched ? data?.mdx?.exports?.plans[1].prices[1] : data?.mdx?.exports?.plans[1].prices[0]}
                </p>
                <p className={"text-white text-center text-lg"}>
                  {planSwitched ? data?.mdx?.exports?.plans[1].subtexts[1] : data?.mdx?.exports?.plans[1].subtexts[0]}
                </p>
              </div>
              <div className={"flex flex-col w-full bg-white text-gray-500 font-semibold text-sm p-5"}>
                <p className={"text-center"}>{data?.mdx?.exports?.plans[1].description}</p>
                <div className={"pt-5 pb-20 h-96"}>
                  {data?.mdx?.exports?.plans[1].features.map((feature, index) => (
                    <div className={"flex flex-row"} key={index}>
                      <i className={feature.icon + " text-primary-default"} />
                      <p className={"pb-2 pl-2 font-light"}>{feature.name}</p>
                    </div>
                  ))}
                </div>
              </div>
              <a onClick={onStartFreeTrial} className={"w-full bg-primary-default border-t-8 border-b-8 border-primary-blockquote p-5 text-center cursor-pointer"}>
                <p className={"w-full text-center text-white hover:text-white text-xl"}>{data?.mdx?.exports?.plans[1].buttonText}</p>
              </a>
            </div>
          </div>
          <div className={"w-full sm:w-2/3 md:w-1/3 px-5 xxl:px-20 cursor-default transition-all duration-300 zoom-on-hover pb-10 md:pb-0"}>
            <div className={"w-full flex flex-col bg-primary-blockquote pt-5 rounded-lg shadow-xl"}>
              <p className={"text-white text-lg md:text-xl text-left px-5 pb-5"}>
                {data?.mdx?.exports?.plans[2].name}
              </p>
              <div className={"flex flex-col w-full bg-primary-default py-8 border-b-8 border-primary-blockquote"}>
                <p className={"text-white text-xl md:text-2xl text-center"}>
                  {planSwitched ? data?.mdx?.exports?.plans[2].prices[1] : data?.mdx?.exports?.plans[2].prices[0]}
                </p>
                <p className={"text-white text-center text-lg"}>
                </p>
              </div>
              <div className={"flex flex-col w-full bg-white text-gray-500 font-semibold text-sm p-5"}>
                <p className={"text-center"}>{data?.mdx?.exports?.plans[2].description}</p>
                <div className={"pt-5 pb-20 h-96"}>
                  {data?.mdx?.exports?.plans[2].features.map((feature, index) => (
                    <div className={"flex flex-row"} key={index}>
                      <i className={feature.icon + " text-primary-default"} />
                      <p className={"pb-2 pl-2 font-light"}>{feature.name}</p>
                    </div>
                  ))}
                </div>
              </div>
              <a href={data?.mdx?.exports?.plans[2].buttonHref} target={"_blank"} className={"w-full bg-primary-default border-t-8 border-b-8 border-primary-blockquote p-5 text-center"}>
                <p className={"w-full text-center text-white hover:text-white text-xl"}>{data?.mdx?.exports?.plans[2].buttonText}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={"w-full flex flex-col px-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96  pt-10"}>
        <p className={"text-sm text-center text-gray-500"}>* {data?.mdx?.frontmatter?.euText}</p>
        <div className={"flex flex-col md:flex-row justify-end items-center pt-5"}>
          <p className={"text-sm text-gray-500 text-right md:pr-5 pb-5 md:pb-0"}>{data?.mdx?.frontmatter?.mobileText}:</p>
          <div className={"flex flex-row"}>
            <AppleStore className={"pr-2"}/>
            <GooglePlay/>
          </div>
        </div>
      </div>
      <div className={"w-full flex flex-col pb-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96 "}>
        <h2 className={"text-lg md:text-xl lg:text-2xl font-light text-primary-default p-5 md:py-10 md:pt-20"}>{data?.mdx?.exports?.features[0].title}</h2>
        <div className={"flex flex-row flex-wrap"}>
          {data?.mdx?.exports?.features[0].list.map((item, index) => (
            <div className={"flex flex-col w-full md:w-1/2 lg:w-1/3 p-5"} key={index}>
              <div className={"flex flex-row items-center pricing-text"}>
                <i className={item.icon + " text-primary-default text-2xl md:text-3xl  lg:text-4xl pr-5"} />
                <h3 className={"text-lg md:text-xl lg:text-xl text-gray-600 font-light"}>{item.name}</h3>
              </div>
              <p className={"text-gray-600 text-sm pt-10"}>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={"w-full flex flex-col pb-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96 "}>
        <h2 className={"text-lg md:text-xl lg:text-2xl font-light text-primary-default p-5 md:py-10 md:pt-20"}>{data?.mdx?.exports?.features[1].title}</h2>
        <div className={"flex flex-row flex-wrap"}>
          {data?.mdx?.exports?.features[1].list.map((item, index) => (
            <div className={"flex flex-col w-full md:w-1/2 lg:w-1/3 p-5"} key={index}>
              <div className={"flex flex-row items-center pricing-text"}>
                <i className={item.icon + " text-primary-default text-2xl md:text-3xl  lg:text-4xl pr-5"} />
                <h3 className={"text-lg md:text-xl lg:text-xl text-gray-600 font-light"}>{item.name}</h3>
              </div>
              <p className={"text-gray-600 text-sm pt-10"}>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={"w-full flex flex-col pb-5 md:px-10 lg:px-20 xl:px-80 xxl:px-96  pb-5 md:pb-5"}>
        <h2 className={"text-lg md:text-xl lg:text-2xl font-light text-primary-default p-5 md:py-10 md:pt-20"}>{data?.mdx?.frontmatter?.discountPolicyTitle}</h2>
        <p className={"text-gray-600 text-sm"}>{data?.mdx?.frontmatter?.discountPolicySubtitle}:</p>
        <ul className={"pt-5 list-disc px-5 md:px-5"}>
          {data?.mdx?.frontmatter?.discountPolicyList.map((item, index) => (
            <li className={"text-gray-600 text-sm"} key={index}>{item}</li>
          ))}
        </ul>

        <p className={"text-lg md:text-lg lg:text-xl text-gray-600 font-light pt-10 pb-10"}>{data?.mdx?.frontmatter?.salesRequests} <a className={"text-primary-blockquote hover:text-primary-blockquote"} href={"mailto:sales@elapseit.com"} target={"_blank"}>sales@elapseit.com</a></p>
      </div>
      <RoContact/>
    </SiteLayout>
  );
};

export default PricingPlans;
